/**
 * items: obj
 * **/

import React from "react";
import {Link, Trans, useTranslation} from "gatsby-plugin-react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../locales/custom-localized-link";
import BookConsultation from "../pages/BookConsultation";

const ElectronicLockLineup = ({
                                label,
                                showMech,
                                showPivot,
                                showCurve,
                                showVersa,
                                showAspire,
                                showOrbit,
                                showRange,
                                showCleo,
                                showCTA
                              }) => {
  const {t} = useTranslation();

  return (
      <section className={'lock-purpose'}>
        <div class="container">
          <h2 dangerouslySetInnerHTML={{__html:t(label)}} />
          <div>
            {
                showCurve &&
                <Link to={'/products/smart-locks/curve/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-curve-ba-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Curve Electronic Lock"
                    />
                    <p>
                      <span>Curve<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showAspire &&
                <Link to={'/products/smart-locks/aspire/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-aspire-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Aspire Electronic Lock"
                    />
                    <p>
                      <span>Aspire<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showVersa &&
                <Link to={'/products/smart-locks/versa/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-versa-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Versa Electronic Lock"
                    />
                    <p>
                      <span>Versa<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showOrbit &&
                <Link to={'/products/smart-locks/orbit/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-orbit-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit Electronic Lock"
                    />
                    <p>
                      <span>Orbit<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showMech &&
                <Link to={'/products/mechanical-locks/mech/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-mech-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Mech Mechanical Lock"
                    />
                    <p>
                      <span>Mech<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showCleo &&
                <Link to={'/products/mechanical-locks/cleo/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/cleo-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Cleo Mechanical Combination Lock "
                    />
                    <p>
                      <span>Cleo<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showRange &&
                <Link to={'/products/electronic-locks/range/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/DL22-range-600x497.png'}
                        loading={'lazy'}
                        width={200}
                        height={148}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Range Electronic Lock"
                    />
                    <p>
                      <span>Range<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
            {
                showPivot &&
                <Link to={'/products/smart-locks/pivot/'}>
                  <div className="thumbnail">
                    <StaticImage
                        src={'../../images/locks/pivot-system.png'}
                        loading={'lazy'}
                        width={201}
                        height={145}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Pivot"
                    />
                    <p>
                      <span>Pivot<sup>&trade;</sup></span>
                    </p>
                  </div>
                </Link>
            }
          </div>
          {
              showCTA &&
              <>
                <br/>
              <div className="smart-lock wrap">
                <BookConsultation />
              </div>
                <br/>
              </>
          }
        </div>
      </section>
  );
};

export default ElectronicLockLineup;