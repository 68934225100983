/**
 * items: obj
 * **/

import React, {useState, useEffect} from "react";

import {Trans} from "gatsby-plugin-react-i18next";
import Modal from '../modals/modal';

const Solution = ({items}) => {
  const isBrowser = typeof window !== "undefined"; //gatsby workaround
  const [showModal, setShowModal] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState(items[0].img);
  const [currentModalBg, setCurrentModalBg] = useState('light');
  const [imgWidth, setImgWidth] = useState(353);
  let bodyElm = null;
  if(isBrowser) {
    bodyElm = document.body;
  }

  let handleShowModal = (e) => {
    console.log(e.target.getAttribute('data-img'));
    setCurrentModalImage(e.target.getAttribute('data-img'));
    setCurrentModalBg(e.target.getAttribute('data-bg'));
    setShowModal(prevShowModal => !prevShowModal);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  let handleCloseModal = () => {
    setShowModal(prevShowModal => !prevShowModal);
    bodyElm.classList.toggle('stop-body-scroll');
  }

  useEffect(() => {
    if(items.length < 4) {
      setImgWidth(349);
    }

  }, [])


  return (
      <div className="uses-items">

        {
          items.map((item, key) => {
            return (
                <div
                    key={key}
                    data-img={item.img}
                    data-bg={item.bg}
                    className="uses-item">
                  <img
                      src={item.img}
                      data-img={item.img}
                      data-bg={item.bg}
                      onClick={handleShowModal}
                      alt={item.alt}
                      width={imgWidth}
                      height={225}
                  />
                  <p>
                    <Trans>{item.label}</Trans>
                  </p>
                </div>
                )
          })

        }
        <Modal show={showModal} handleClose={handleCloseModal} cls={'no-border'} background={currentModalBg}>
          <div className="modal-content">
            <img src={currentModalImage} alt="solution"/>
          </div>
        </Modal>
      </div>
  );
};

export default Solution;